.ag-theme-material {
    /* customise with CSS variables */
    --ag-grid-size: 8px;
    --ag-header-height: 50px;
    --ag-font-family: "Roboto";
    --ag-header-column-resize-handle-display: block;
    --ag-header-column-resize-handle-height: 30%;
    --ag-header-column-resize-handle-width: 2px;
    --ag-header-column-resize-handle-color: #b0b3af;

    --ag-border-radius: 10px;
}
.ag-theme-material .ag-header {
    /* or with CSS selectors targeting grid DOM elements */
    background-color: #f4f5f4;
}

.ag-theme-quartz .ag-text-field-input {
    background-color: rgb(255, 209, 123); /* light orange */
}